import { createRouter, createWebHistory } from "vue-router";
import MapBan from "../views/MapBan.vue";
import HomePage from "../views/HomePage.vue";
import SponsorsPage from '../views/SponsorsPage.vue';
import SponsorsPageClean from '../views/SponsorsPageClean.vue';
import AgentSelection from '../views/AgentSelection.vue';
import TeamVsTeam from '../views/TeamVsTeam.vue';
import ScoreboardPage from '../views/ScoreboardPage.vue';

const routes = [
  {
    path: "/map-ban/:lang/:id",
    component: MapBan,
    meta: { title: "Map Ban - Softwave" },
    props: true,
  },
  {
    path: "/",
    component: HomePage,
    meta: { title: "Stream - Softwave" },
    props: true,
  },
  {
    path: '/sponsors/:id',
    component: SponsorsPage,
    meta: { title: "Sponsors - Softwave" },
    props: true,
  },
  {
    path: '/sponsors/clean/:id',
    component: SponsorsPageClean,
    meta: { title: "Sponsors Clean - Softwave" },
    props: true,
  },
  {
    path: '/agent-selection/:lang/:id',
    component: AgentSelection,
    meta: { title: "Agent Selection - Softwave" },
    props: true,
  },
  {
    path: '/team-vs-team/:lang/:id',
    component: TeamVsTeam,
    meta: { title: "Team VS Team - Softwave" },
    props: true,
  },
  {
    path: '/scoreboard/:lang/:id',
    component: ScoreboardPage,
    meta: { title: "Scoreboard - Softwave" },
    props: true,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.afterEach((to) => {
    document.title = to.meta.title || "Softwave";
  });

export default router;
