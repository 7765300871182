<template>
  <div class="frame-container">
    <div class="holder">
      <div class="image-container">
        <img :src="currentImage.url" :alt="currentImage.name" :style="{ opacity: imageOpacity }" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      apiUrl: `https://api.softwave.dev/v2/hud/sponsors/${this.id}`,
      images: [],
      currentIndex: 0,
      currentImage: {
        url: "https://cdn.softwave.dev/web/powered_by_softwave2.png",
        name: "Softwave Logo",
        active: true,
      },
      imageOpacity: 0,
    };
  },
  methods: {
    fetchImages() {
      fetch(this.apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Respuesta de la API no fue exitosa.');
          }
          return response.json();
        })
        .then((data) => {
          this.images = data.imgs.filter((img) => img.active);
          if (this.images.length > 0) {
            this.images.push(this.currentImage);
            this.changeImage();
            setInterval(this.changeImage, 15000);
          } else {
            this.showStaticLogo();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showStaticLogo();
        });
    },
    showStaticLogo() {
      this.currentImage = {
        url: "https://cdn.softwave.dev/web/powered_by_softwave2.png",
        name: "Softwave Logo",
        active: true,
      };
      this.imageOpacity = 1;
    },
    changeImage() {
      if (this.images.length > 0) {
        this.imageOpacity = 0;
        setTimeout(() => {
          this.currentImage = this.images[this.currentIndex];
          this.imageOpacity = 1;
          this.currentIndex = (this.currentIndex + 1) % this.images.length;
        }, 1500);
      }
    },
  },
  mounted() {
    this.fetchImages();
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  padding: 10px 0;
}

.image-container img {
  display: block;
  transition: opacity 1s ease-in-out;
}

.frame-container {
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.holder {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.93);
  animation: fadeIn 2s;
  display: flex;
  width: 275px;
  justify-content: center;
  height: 90px;
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
