<template>
  <main>
    <div
      v-if="showElements"
      class="base-container slide-in-main"
      :style="containerStyle"
    >
      <div class="top-part-total">
        <div class="team-1-name-logo">
          <div class="team-logo slide-in-left">
            <img
              :src="teamsData.team1.logo"
              class="team-logo-top-header"
              alt=""
            />
          </div>
          <div class="team-name slide-top">{{ teamsData.team1.name }}</div>
        </div>
        <div class="vs-icon-top">
          <svg
            width="50"
            height="57"
            viewBox="0 0 84 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.7047 24.97L61.1897 26.48C61.8397 26.575 62.3798 26.86 62.8098 27.325C63.2398 27.795 63.4597 28.35 63.4597 29V34.51C63.4597 35.205 63.2097 35.805 62.7047 36.31L59.7548 39.26C59.2498 39.765 58.6497 40.015 57.9547 40.015H48.5198C47.8248 40.015 47.2247 39.765 46.7197 39.26L43.0098 35.55L46.5747 31.95L49.3098 34.685C49.4548 34.83 49.6197 34.9 49.8147 34.9H56.5498C56.7398 34.9 56.9098 34.83 57.0548 34.685L58.0647 33.675C58.1597 33.58 58.2097 33.41 58.2097 33.17V31.73C58.2097 31.56 58.1448 31.405 58.0098 31.26C57.8798 31.115 57.7148 31.03 57.5248 31.01L46.0747 29.535C45.4247 29.44 44.8847 29.155 44.4547 28.69C44.0247 28.22 43.8048 27.665 43.8048 27.015V21.505C43.8048 20.81 44.0548 20.21 44.5598 19.705L47.5098 16.755C48.0148 16.25 48.6148 16 49.3098 16H58.4198C59.1898 16 59.7897 16.25 60.2197 16.755L63.8898 20.465L60.3247 24.065L57.5897 21.33C57.4447 21.185 57.2797 21.115 57.0847 21.115H50.7097C50.5197 21.115 50.3497 21.185 50.2047 21.33L49.1947 22.265C49.0997 22.36 49.0498 22.53 49.0498 22.77V24.245C49.0498 24.435 49.1148 24.6 49.2498 24.73C49.3848 24.865 49.5347 24.945 49.7047 24.97Z"
              fill="#fff"
            ></path>
            <path
              d="M26.2649 40.015L20.1099 16H25.6898L30.7999 36.38L35.9099 16H41.2749L35.1549 40.015H26.2649Z"
              fill="#fff"
            ></path>
          </svg>
        </div>
        <div class="team-2-name-logo">
          <div class="team-name slide-top">{{ teamsData.team2.name }}</div>
          <div class="team-logo slide-in-right">
            <img
              :src="teamsData.team2.logo"
              class="team-logo-top-header"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div id="team1" class="team">
          <agent-card
            v-for="(player, index) in teamsData.team1.players"
            :key="index"
            :player="player"
            :team-number="1"
            :delay="2 + index * 0.5"
          />
        </div>
        <div class="center-div fade-in">
          <div class="top-part-vs">
            <div class="map-container">
              <div class="powered-by">
                <img
                  src="https://cdn.softwave.dev/web/powered_by_softwave2.png"
                  class="powered-by-img"
                  alt=""
                />
              </div>
              <img :src="currentMapUrl" class="map-playing-img" alt="" />
            </div>
          </div>
          <div class="bottom-part-vs"></div>
        </div>
        <div id="team2" class="team">
          <agent-card
            v-for="(player, index) in teamsData.team2.players"
            :key="index"
            :player="player"
            :team-number="2"
            :delay="2 + index * 0.5"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AgentSelection",
  props: ['lang', 'id'],
  components: {
    AgentCard: () => import('./AgentCard.vue')
  },
  data() {
    return {
      config: { agent_selection: { colors: {} } },
      teamsData: { team1: { players: [] }, team2: { players: [] } },
      mapData: { mapas: [] },
      showElements: true,
      socket: null
    };
  },
  computed: {
    containerStyle() {
      return {
        "--text-color": this.config.agent_selection.colors.text,
        "--team-1-color": this.config.agent_selection.colors.team_1,
        "--team-2-color": this.config.agent_selection.colors.team_2,
      };
    },
    currentMapUrl() {
      const currentMap = this.mapData.mapas.find(
        (map) => map.defensor_team && !map.completed
      );
      return currentMap ? currentMap.map_url : "";
    }
  },
  methods: {
    async fetchConfig() {
      try {
        const response = await fetch(
          `https://api.softwave.dev/v2/hud/controller/${this.id}`
        );
        this.config = await response.json();
        if (this.config.agent_selection.auto_play) {
          this.fetchTeamsData();
        }
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    },
    async fetchTeamsData() {
      try {
        const response = await fetch(
          `https://api.softwave.dev/v2/hud/agents/${this.id}`
        );
        this.teamsData = await response.json();
        this.showElements = true;
      } catch (error) {
        console.error("Error fetching teams data:", error);
      }
    },
    async fetchMapData() {
      try {
        const response = await fetch(
          `https://api.softwave.dev/v2/hud/maps/${this.id}`
        );
        this.mapData = await response.json();
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    },
    hideElements() {
      this.showElements = false;
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    },
    initWebSocket() {
      this.socket = new WebSocket(`wss://ws.internal.softwave.dev`);

      this.socket.onopen = () => {
        console.log("WebSocket connection opened.");
        this.socket.send(JSON.stringify({ type: "init", id: this.id }));
      };

      this.socket.onmessage = (event) => {
        const message = JSON.parse(event.data);

        switch (message.type) {
          case "submitDataMaps":
          case "loadMapsData":
            this.fetchMapData();
            break;
          case "submitDataAgents":
          case "play_agent_selection":
            if (!this.config.agent_selection.auto_play) {
              this.fetchTeamsData();
            }
            break;
          case "remove_agent_selection":
            this.hideElements();
            break;
          case "restart_agent_selection":
            window.location.reload();
            break;
          case "updateMapBanController":
            this.fetchConfig();
            break;
        }
      };
    }
  },
  mounted() {
    this.fetchConfig();
    this.fetchMapData();
    this.initWebSocket();
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  }
};
</script>


<style scoped>
body {
  margin: 0;
  padding: 0;
  background-color: none;
  font-family: "Switzer-Variable";
  width: 1920px;
  height: 1080px;
  overflow: hidden;
}

.base-container {
  width: 100%;
  height: 330px;
  background-color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  overflow: hidden;
  position: absolute;
}

.container {
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
}

.team {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agent-container-full {
  width: 160px;
  height: 270px;
  overflow: hidden;
  background-color: rgb(0 0 0 / 62%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.top-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 215px;
}

.utility-icon-1 {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  position: absolute;
  z-index: 1000;
  left: 0;
}

.utility-icon-2 {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  z-index: 1000;
}

.agent-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transform: scale(3.2) translateY(30%);
}

.bottom-part {
  text-align: start;
  height: 62px;
}

.inside {
  padding: 10px;
}

.agent-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
}

.player-name {
  color: rgb(0 0 0 / 70%);
  font-size: 16px;
  text-transform: uppercase;
}

.map-container {
  position: relative;
  width: 200px;
  height: 270px;
  padding: 0 20px;
  overflow: hidden;
}

.map-playing-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.map-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

div#team1,
#team2 {
  height: 270px;
  position: relative;
  bottom: 0;
}

.powered-by {
  position: absolute;
  bottom: 0;
  z-index: 100;
  background: linear-gradient(to top, rgb(0 0 0 / 88%), rgb(0 0 0 / 0%));
  left: 0px;
  width: 240px;
  height: 94px;
  display: flex;
  align-items: center;
}

img.powered-by-img {
  width: 190px;
  display: flex;
  margin: auto;
  margin-top: 30px;
}

.top-part-total {
  z-index: 10000;
  display: flex;
  gap: 110px;
  font-size: 24px;
  position: absolute;
  top: 0;
  height: 60px;
  align-items: center;
  width: 96%;
}

.vs-icon-top {
  display: flex;
}

.team-1-color-u,
.team-1-color-b {
  background-color: var(--team-1-color);
}

.team-2-color-u,
.team-2-color-b {
  background-color: var(--team-2-color);
}

.team-1-color-t {
  color: var(--text-color-2);
}

.team-2-color-t {
  color: var(--text-color-2);
}

.team-1-name-logo,
.team-2-name-logo {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 60px;
}

img.team-logo-top-header {
  display: flex;
}

.team-logo {
  display: flex;
  height: 200px;
  opacity: 0.8;
}

.team-name {
  font-size: 32px;
  font-weight: 500;
}

img.agent-ulti-img {
  display: flex;
  height: 25px;
  padding: 8px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-left {
  animation: slideInLeft 1.2s ease-in-out;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-right {
  animation: slideInRight 1s ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in {
  animation: slideIn 0.8s ease-in-out;
}

@keyframes slideTop {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-top {
  animation: slideTop 1.2s ease-in-out;
}

@keyframes fadeInAgents {
  0% {
    opacity: 0;
    transform: scale(2);
  }
  80% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
  }
}

.fade-in-agents {
  animation: fadeInAgents 0.5s ease-in-out forwards;
  opacity: 0;
}

@keyframes slideInMain {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(1);
  }
}

.slide-in-main {
  animation: slideInMain 0.6s ease-in-out;
}

.base-container,
.top-part-total,
.center-div {
  display: none;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

.fade-out {
  animation: fadeOut 1s ease-in-out forwards;
}

@keyframes slideOutLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-out-left {
  animation: slideOutLeft 1.2s ease-in-out forwards;
}

@keyframes slideOutRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.slide-out-right {
  animation: slideOutRight 1s ease-in-out forwards;
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

.slide-out {
  animation: slideOut 0.8s ease-in-out forwards;
}
</style>
