<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
@font-face {
    font-family: "Switzer-Variable";
    src: url("https://cdn.softwave.dev/fonts/Switzer-Variable.woff2") format("woff2"),
        url("https://cdn.softwave.dev/fonts/Switzer-Variable.woff") format("woff"),
        url("https://cdn.softwave.dev/fonts/Switzer-Variable.ttf") format("truetype");
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
}
@font-face {
  font-family: Replica-Bold;
  src: url(https://cdn.softwave.dev/fonts/Replica-Bold.ttf) format("truetype");
}
@font-face {
  font-family: ReplicaLL;
  src: url(https://cdn.softwave.dev/fonts/ReplicaLL-Bold.otf) format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: Replica-Light;
  src: url(https://cdn.softwave.dev/fonts/Replica-Light.ttf) format("truetype");
}

@font-face {
  font-family: Replica-Regular;
  src: url(https://cdn.softwave.dev/fonts/Replica-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Quantico;
  src: url(https://cdn.softwave.dev/fonts/Quantico-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Quantico-Italic;
  src: url(https://cdn.softwave.dev/fonts/Quantico-Italic.ttf) format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: Quantico-Bold;
  src: url(https://cdn.softwave.dev/fonts/Quantico-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Quantico-Bold-Italic;
  src: url(https://cdn.softwave.dev/fonts/Quantico-BoldItalic.ttf) format("truetype");
}

body {
  overflow: hidden;
  margin: 0;
}
</style>