<template>
    <main>
        <div class="main-cont-data">
            <div class="left-side">
                <div class="top-part">
                    <div class="left-side-top-logo-l"><img class="logo-inside-top" src="https://cdn.softwave.dev/042a9dd8-3e31-4fa6-b20f-b72193d139e6/XSOUL_logo.png" alt="" srcset=""></div>
                    <div class="right-side-top-logo-l">
                        <div class="team-name">TEAM NAME 1</div>
                    </div>
                </div>
                <div class="bottom-part">
                    <div class="hedaer-btn-part-names">
                        <div class="player-name-container">
                            PLAYER
                        </div>
                        <div class="k-d-a-container">
                            K D A
                        </div>
                        <div class="abilities-container">
                            ABILITIES
                        </div>
                        <div class="shield-container">
                            SHIELD
                        </div>
                        <div class="loadout-container">
                            LOADOUT
                        </div>
                        <div class="creds-container">
                            CREDS
                        </div>
                    </div>
                    <div class="player-container">
                        <div class="player-image">
                            <img class="agent-image-l" src="https://cdn.softwave.dev/assets/valorant/agents/Astra/displayicon.png" alt="" srcset="">
                        </div>
                        <div class="player-name border-left-side-r">
                            JUANE
                        </div>
                        <div class="player-k-d-a border-left-side-r">
                            10 / 21 / 38
                        </div>
                        <div class="player-abilities">
                            <img class="abiliti" src="https://cdn.softwave.dev/assets/valorant/agents/Astra/a1.svg" alt="" srcset="">
                            <img class="abiliti" src="https://cdn.softwave.dev/assets/valorant/agents/Astra/a2.svg" alt="" srcset="">
                            <img class="abiliti" src="https://cdn.softwave.dev/assets/valorant/agents/Astra/a3.svg" alt="" srcset="">
                            <img class="abiliti" src="https://cdn.softwave.dev/assets/valorant/agents/Astra/a4.svg" alt="" srcset="">
                            <div class="ulti-container">
                                <img class="ulti-image" src="https://cdn.softwave.dev/assets/valorant/agents/Astra/X.png" alt="" srcset="">
                            </div>
                        </div>
                    </div>
                    <div class="player-container odd">
                        <div class="player-image">
                            <img class="agent-image-l" src="https://cdn.softwave.dev/assets/valorant/agents/Jett/displayicon.png" alt="" srcset="">
                        </div>
                        <div class="player-name">
                            JUANE
                        </div>
                        <div class="player-k-d-a">
                            10 / 21 / 38
                        </div>
                        <div class="player-abilities">

                        </div>
                    </div>

                </div>
            </div>
            <div class="right-side">
                <div class="top-part">
                    <div class="right-side-top-logo-r right-side-c">
                        <div class="team-name">TEAM NAME 1</div>
                    </div>
                    <div class="left-side-top-logo-r"><img class="logo-inside-top" src="https://cdn.softwave.dev/042a9dd8-3e31-4fa6-b20f-b72193d139e6/XSOUL_logo.png" alt="" srcset=""></div>
                </div>
                <div class="bottom-part"></div>
            </div>
        </div>
    </main>
</template>

<style scoped>
main {
    width: 1920px;
    position: absolute;
    height: 1080px;
    font-family: "Switzer-Variable";
}
.right-side-c {
    justify-content: end;
}
.team-name {
    font-size: 34px;
    padding: 25px;
    font-weight: bold;
    color: #fff;
}
.left-side, .right-side {
    width: 50%;
    display: grid;
    gap: 20px;
}
.main-cont-data {
    display: flex;
    gap: 25px;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 25px;
}
.top-part {
    display: flex;
}
.left-side-top-logo-l {
    background-color: #00121c;
    width: 100px;
    height: 100px;
    display: flex;
    border-right: 4px solid #35f8b6;
}
.logo-inside-top {
    width: 100%;
    margin: 10px;
}
.right-side-top-logo-l {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    background-color: #191e23;
}

.left-side-top-logo-r {
    background-color: #00121c;
    width: 100px;
    height: 100px;
    display: flex;
    border-left: 4px solid #e03663;
}
.right-side-top-logo-r {
    height: 100px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #191e23;
}
.bottom-part {
    width: 100%;
}
.hedaer-btn-part-names {
    display: flex;
    width: 100%;
    color: #fff;
    padding: 5px 0;
    justify-content: space-around;
    font-weight: 500;
    background-color: #191e23;
}
.player-name-container {
    width: 250px;
    text-align: center;
}
.k-d-a-container {
    width: 140px;
    text-align: center;
}
.abilities-container {
    width: 280px;
    text-align: center;
}
.shield-container {
    width: 80px;
    text-align: center;
}
.loadout-container {
    width: 100px;
    text-align: center;
}
.creds-container {
    width: 100px;
    text-align: center;
}
.player-container {
    background-color: #242f36;
    display: flex;
    color: #fff;
    width: 100%;
}
.player-name {
    display: flex;
    align-items: center;
    font-size: 20px;
    padding-left: 25px;
    width: 160px;
}
.player-k-d-a {
    display: flex;
    padding: 0 10px;
    align-items: center;
    font-size: 20px;
    width: 100px;
}
.agent-image-l {
    width: 70px;
    display: flex;
    background: linear-gradient(90deg, rgba(53,248,182,1) 0%, rgba(0,0,0,0) 71%);
}
.player-abilities {
    align-items: center;
    display: flex;
    gap: 5px;
    width: 280px;
    padding: 0 15px;
}
.border-left-side-r {
    border-right: 3px solid #14191f;
}
.odd {
    background-color: #191e23;
}
.ulti-container {
    border-left: 3px solid #14191f;
    border-right: 3px solid #14191f;
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 100%;
}
.abiliti {
    width: 40px;
}
.ulti-image {
    width: 60px;
    display: flex;
}
</style>