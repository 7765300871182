<template>
  <main>
    <div class="map-ban-base-cont">
      <div v-for="(mapa, index) in mapas" :key="index" class="map-container" :style="{ animationDelay: `${index * 0.6}s` }">
        <div class="bottom-part-gradient">
          <div class="main-cont-texts">
            <div class="text-top-part">
              {{ type === 'BO1' && index === mapas.length - 1 ? 'SELECTED MAP' : (index === mapas.length - 1 ? 'DECIDER' : `${mapa.defensor_team ? 'PICKED BY' : 'BANNED BY'} `) }}
              <span class="team-name">{{ mapa.picked_by }}</span>
            </div>
          </div>
        </div>
        <div class="map-image-team-banned">
          <div v-if="!mapa.defensor_team" class="banned-map-svg">
            <svg class='svg-asset' :style="{ animationDelay: `${index * 0.5}s` }" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M59.1998 59.1998H45.3346V68H68V45.3346H59.1998V59.1998ZM0 45.3307V67.9961H22.6654V59.1959H8.80023V45.3307H0ZM8.80023 22.6654V8.80023H22.6654V0H0V22.6654H8.80023ZM45.3346 8.80023H59.1998V22.6654H68V0H45.3346V8.80023Z" fill="var(--svg-x-color)"></path>
              <path d="M40.0939 33.9995L49.6973 24.396V18.3018H43.6031L35.3955 26.5093C35.3955 32.3579 35.3955 35.6371 35.3955 41.4857L43.6031 49.6933H49.6973V43.599L40.0939 33.9995Z" fill="var(--svg-x-color)"></path>
              <path d="M27.9057 33.9995L18.3022 43.6029V49.6972H24.3965L32.6041 41.4896C32.6041 35.641 32.6041 32.3618 32.6041 26.5132L24.3965 18.3057H18.3022V24.3999L27.9057 33.9995Z" fill="var(--svg-x-color)"></path>
            </svg>
          </div>
          <div v-if="mapa.completed" class="completed-map-info" :style="{ animationDelay: `${index * 0.9}s` }">
            <img :src="mapa.winner_team_logo" alt="Team Logo" class="team-logo-completed">
            <div class="score-completed-map">{{ mapa.score_left_team }} - {{ mapa.score_right_team }}</div>
          </div>
          <div v-if="mapa.defensor_team" class="picked-gradiente" :style="{ animationDelay: `${index * 0.5 + 0.5}s` }">
            <div class="text-defender">DEF {{ mapa.defensor_team }}</div>
          </div>
          <img :src="mapa.map_url" alt="Map Image" class="map-img" :class="{ completed: mapa.completed, banned: !mapa.defensor_team }" :style="{ animationDelay: `${index * 0.9 + 0.5}s` }">
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  props: ['lang', 'id'],
  data() {
    return {
      socket: null,
      mapas: [],
      apiUrl: `https://api.softwave.dev/v2/hud/maps/${this.id}`,
      colorsApiUrl: `https://api.softwave.dev/v2/hud/controller/${this.id}`,
      type: '',
    };
  },
  methods: {
    async init() {
      await this.fetchData();
      await this.fetchColorsAndApply();
    },
    async fetchColorsAndApply() {
      try {
        const response = await fetch(this.colorsApiUrl);
        if (!response.ok) {
          if (response.status === 500) {
            this.changeAnimationPlayStateAfterDelay('running', 100);
          }
          throw new Error('API responded with an error');
        }
        const data = await response.json();
        if (data.map_ban) {
          this.applyColorsToRoot(data.map_ban.colors);
          if (data.map_ban.auto_play) {
            this.ensureAnimationRuns(7, 100);
          }
        }
      } catch (error) {
        console.error("Error loading configuration from API:", error);
      }
    },
    ensureAnimationRuns(times, interval = 100) {
      let attempts = 0;
      const attemptToAddAnimation = () => {
        const elements = document.querySelectorAll('.map-container, .completed-map-info, .map-image-team-banned, .svg-asset, .banned-gradiente, .picked-gradiente');
        elements.forEach(element => {
          if (element && !element.classList.contains('animation-running')) {
            element.classList.add('animation-running');
          }
        });
        attempts += 1;
        if (attempts < times) {
          setTimeout(attemptToAddAnimation, interval);
        }
      };
      attemptToAddAnimation();
    },
    changeAnimationPlayStateAfterDelay(state, delay = 100) {
      setTimeout(() => {
        document.querySelectorAll('.map-container, .completed-map-info, .map-image-team-banned, .svg-asset, .banned-gradiente, .picked-gradiente').forEach(element => {
          if (element) {
            if (state === 'running') {
              element.classList.add('animation-running');
            } else {
              element.classList.remove('animation-running');
            }
          }
        });
      }, delay);
    },
    restartMapBanAnimation() {
      const elements = document.querySelectorAll('.map-container');
      const totalElements = elements.length;
      elements.forEach((element, index) => {
        if (element) {
          let delay = (totalElements - 1 - index) * 600;
          setTimeout(() => {
            setTimeout(() => {
              element.classList.add('fade-out-up');
              element.classList.remove('animation-running');
            }, 500);
            const svgElement = element.querySelector('.svg-asset');
            if (svgElement) {
              svgElement.classList.add('svg-out-animation');
            }
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }, delay);
        }
      });
    },
    applyColorsToRoot(colors) {
      const root = document.documentElement;
      root.style.setProperty('--text-color', colors.text);
      root.style.setProperty('--svg-x-color', colors.svg_x);
      root.style.setProperty('--gradient-pick-color', colors.gradient_pick);
      root.style.setProperty('--gradient-1-header-color', colors.gradient_1_header);
      root.style.setProperty('--gradient-2-header-color', colors.gradient_2_header);
    },
    playAnimations() {
      document.querySelectorAll('.map-container, .completed-map-info, .map-image-team-banned, .svg-asset, .banned-gradiente, .picked-gradiente').forEach(element => {
        if (element) {
          element.classList.add('animation-running');
        }
      });
    },
    async fetchData() {
      try {
        const response = await fetch(this.apiUrl);
        const data = await response.json();
        this.mapas = data.mapas;
        this.type = data.type;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    },
  },
  mounted() {
    this.init();
    this.socket = new WebSocket(`wss://ws.internal.softwave.dev`);
    this.socket.onopen = () => {
      console.log('Conexión WebSocket abierta.');
      this.socket.send(JSON.stringify({ type: 'init', id: this.id }));
    };
    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (['submitDataMaps', 'loadMapsData'].includes(message.type)) {
        this.fetchData();
      }
      if (message.type === 'play_map_ban') {
        this.playAnimations();
      }
      if (message.type === 'remove_map_ban') {
        this.restartMapBanAnimation();
      }
      if (message.type === 'restart_map_ban') {
        window.location.reload();
      }
      if (message.type === 'updateMapBanController') {
        this.fetchData();
        this.fetchColorsAndApply();
      }
    };
  },
};
</script>


<style scoped>
:root {
  --text-color: #fff;
  --svg-x-color: #3854F2;
  --gradient-pick-color: #3854F2;
  --gradient-1-header-color: #3854F2;
  --gradient-2-header-color: #3854F2;
}


body {
    margin: 0;
    overflow: hidden;
}

main {
    margin: auto;
    justify-content: center;
    display: flex;
    position: absolute;
    bottom: 100px;
    width: 1920px;
}

.map-ban-base-cont {
    width: 1750px;
    height: 360px;
    display: flex;
}

.map-container {
    width: 250px;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
}
.completed-map-info {
    height: 280px;
    opacity: 0;
    position: absolute;
    z-index: 1000;
    display: table;
    top: 50%;
    margin: auto;
    text-align: center;
    left: 50%;
    animation: fadeInOpacityO 0.5s ease-out forwards;
    transform: translate(-50%, -50%);
}

.team-logo-completed {
    height: 90px;
    display: flex;
    align-items: center;
    width: 90px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.map-image-team-banned {
    width: 250px;
    height: 280px;
    display: flex;
    overflow: hidden;
    opacity: 0;
    position: relative;
    animation: fadeInOp 1s ease-out forwards;
}

.map-img {
    object-fit: cover;
    filter: brightness(1.3);
    width: 100%;
}

.bottom-part-gradient {
    width: 100%;
    height: 80px;
    background: var(--gradient-1-header-color);
    background: -moz-linear-gradient(90deg, var(--gradient-1-header-color) 0%, var(--gradient-2-header-color) 100%);
    background: -webkit-linear-gradient(90deg, var(--gradient-1-header-color) 0%, var(--gradient-2-header-color) 100%);
    background: linear-gradient(90deg, var(--gradient-1-header-color) 0%, var(--gradient-2-header-color) 100%);
}

.banned-map-svg {
    z-index: 101;
    position: absolute;
    bottom: 0;
    top: 50%;
    left: 50%;
    height: 68px;
    transform: translate(-50%, -50%);
}

.banned {
    filter: grayscale(0.8);
}

.banned-gradiente {
    width: 100%;
    height: 280px;
    position: absolute;
    z-index: 100;
    background: rgb(56, 84, 242);
    background: -moz-linear-gradient(0deg, rgba(56, 84, 242, 1) 0%, rgba(255, 255, 255, 0) 40%);
    background: -webkit-linear-gradient(0deg, rgba(56, 84, 242, 1) 0%, rgba(255, 255, 255, 0) 40%);
    background: linear-gradient(0deg, rgba(56, 84, 242, 1) 0%, rgba(255, 255, 255, 0) 40%);

}
.black-above {
    width: 100%;
    height: 280px;
    position: absolute;
    z-index: 90;
    background: rgb(0 0 0 / 61%);
}

.main-cont-texts {
    height: 100%;
    display: flex;
    align-items: center;
}

.text-top-part {
    width: 100%;
    font-size: 18px;
    align-items: center;
    display: grid;
    justify-content: center;
    font-weight: 600;
    font-family: Quantico-Bold;
    color: #fff;
    text-align: center;
    line-height: 18px;
}

.team-name {
    color: var(--text-color);
    text-align: center;
    font-size: 24px;
    margin-top: 5px;
}

.picked-gradiente {
    width: 100%;
    height: 280px;
    position: absolute;
    opacity: 0;
    z-index: 100;
    display: flex;
    background: var(--gradient-pick-color);
    background: -moz-linear-gradient(0deg, var(--gradient-pick-color) 0%, rgba(255, 255, 255, 0) 40%);
    background: -webkit-linear-gradient(0deg, var(--gradient-pick-color) 0%, rgba(255, 255, 255, 0) 40%);
    background: linear-gradient(0deg, var(--gradient-pick-color) 0%, rgba(255, 255, 255, 0) 40%);

}

.text-defender {
    display: flex;
    position: absolute;
    bottom: 0;
    padding: 10px;
    font-size: 18px;
    font-family: Quantico-Bold;
    color: #fff;
    text-shadow: 0px 0px 20px #000;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(400px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes fadeInOpacityO {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeGradient {
    0% {
        transform: translateY(400px);
        transform-origin: bottom;
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        transform-origin: bottom;
        opacity: 1;
    }
}

@keyframes fadeInOp {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaleAndRotate {
    0% {
        transform: scale(0) rotate(0deg);
        opacity: 0;
    }
    40% {
        transform: scale(1.3) rotate(180deg);
        opacity: 1;
    }
    100% {
        transform: scale(1) rotate(360deg);
    }
}


@keyframes scaleAndRotateOut {
    0% {
        transform: scale(1) rotate(0deg);
    }
    40% {
        transform: scale(1.3) rotate(180deg);
        opacity: 1;
    }
    100% {
        transform: scale(0) rotate(360deg);
    }
}
.score-completed-map {
    font-size: 28px;
    color: #fff;
    font-weight: 600;
    font-family: Quantico-Bold;
    text-shadow: 0px 0px 20px #000;
    margin-top: 81px;
}

.completed {
    filter: grayscale(4);
}
.svg-asset {
    width: 100%;
    height: 100%;
    animation: scaleAndRotate 2s ease-in forwards;
}

@keyframes fadeOutUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(400px);
        opacity: 0;
    }
}

.fade-out-up {
    animation: fadeOutUp 0.5s ease-out forwards!important;
}

.banned-gradiente,
.picked-gradiente {
    animation: fadeGradient 0.6s ease-out forwards;
    transform-origin: bottom;
}

.map-container,
.completed-map-info,
.map-image-team-banned,
.svg-asset,
.banned-gradiente,
.picked-gradiente {
    animation-play-state: paused;
}

.animation-running {
    animation-play-state: running;
}

</style>