<template>
    <main :class="{ 'visible': animateTeams }">
      <div class="background-container" :class="{ 'animate': animateTeams }">
        <video class="background-video" autoplay loop muted>
          <source src="https://softwave-cdn.com/upload/external/BREACH_B_SEASON5_BLUE_1080.webm" type="video/webm">
        </video>
      </div>
      <div class="overlay-image2" :class="{ 'animate': animateTeams }"></div>
      <div class="overlay-image" :class="{ 'animate': animateTeams }"></div>
      <div class="team-container">
        <div class="team-wrapper team1-wrapper" :class="{ 'animate': animateTeams }">
          <div class="team-container-logo team1">
            <img :src="team1.logo" alt="Team 1 Logo" class="team-logo">
          </div>
        </div>
        <div class="team-name-div-btn-left" :class="{ 'animate': animateTeams }">
          <div class="team-name-inside">{{ team1.name }}</div>
        </div>
        <div class="team-name-div-btn-right" :class="{ 'animate': animateTeams }">
          <div class="team-name-inside">{{ team2.name }}</div>
        </div>
        <img src="https://softwave-cdn.com/upload/overlays/vs_svg.svg" class="svg_vs_animation_main" :class="{ 'animate': animateTeams }" alt="">
        <div class="team-wrapper team2-wrapper" :class="{ 'animate': animateTeams }">
          <div class="team-container-logo team2">
            <img :src="team2.logo" alt="Team 2 Logo" class="team-logo">
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    data() {
      return {
        team1: {
          name: '',
          logo: ''
        },
        team2: {
          name: '',
          logo: ''
        },
        animateTeams: false,
        autoPlay: false,
        dataApiUrl: 'https://api.softwave.dev/v2/hud/data/042a9dd8-3e31-4fa6-b20f-b72193d139e6',
        controllerApiUrl: 'https://api.softwave.dev/v2/hud/controller/042a9dd8-3e31-4fa6-b20f-b72193d139e6',
        socket: null,
      };
    },
    methods: {
      async fetchData() {
        try {
          const response = await fetch(this.dataApiUrl);
          const data = await response.json();
          this.team1.name = data.team_1_name;
          this.team1.logo = data.team_1_logo;
          this.team2.name = data.team_2_name;
          this.team2.logo = data.team_2_logo;
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      },
      async fetchControllerData() {
        try {
          const response = await fetch(this.controllerApiUrl);
          const data = await response.json();
          this.autoPlay = data.team_vs_team.auto_play;
          if (this.autoPlay) {
            this.startAnimation();
          }
          console.log("autoPlay value from API:", data.team_vs_team.auto_play);
        } catch (error) {
          console.error('Failed to fetch controller data:', error);
        }
      },
      startAnimation() {
        this.animateTeams = true;
      },
      handleWebSocketMessage(message) {
        if (message.type === 'play_team_vs_team') {
          this.startAnimation();
        }
        if (message.type === 'restart_team_vs_team') {
          this.restartAnimation();
        }
      },
      restartAnimation() {
        window.location.reload();
      },
      setupWebSocket() {
        this.socket = new WebSocket(`wss://ws.internal.softwave.dev`);
        this.socket.onopen = () => {
          console.log('Conexión WebSocket abierta.');
          this.socket.send(JSON.stringify({ type: 'init', id: '042a9dd8-3e31-4fa6-b20f-b72193d139e6' }));
        };
        this.socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          this.handleWebSocketMessage(message);
        };
      },
    },
    async mounted() {
      await this.fetchData();
      await this.fetchControllerData();
      this.setupWebSocket();
    },
  };
  </script>
  
  <style scoped>
  :root {
    --text-color: #fff;
  }
  
  body {
    margin: 0;
    font-family: "Switzer-Variable";
    overflow: hidden;
    background-color: black;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
  }
  
  main {
    margin: auto;
    justify-content: center;
    display: flex;
    position: absolute;
    width: 1920px;
    height: 1080px;
    opacity: 0;
  }
  
  main.visible {
    opacity: 1;
  }
  
  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    opacity: 0;
  }
  
  .background-container.animate {
    animation: showBackground 0.5s forwards 0.5s;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://softwave-cdn.com/upload/overlays/STATIC_1_BW_DARK.png');
    background-size: cover;
    z-index: 1;
    opacity: 0;
  }
  
  .overlay-image.animate {
    animation: slideDown 2s ease-in-out forwards;
    animation-delay: 0.1s;
  }
  
  .overlay-image2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://softwave-cdn.com/upload/overlays/STATIC_1_BW_DARK.png');
    background-size: cover;
    z-index: 1;
    opacity: 0;
  }
  
  .overlay-image2.animate {
    animation: slideDown 2s ease-in-out forwards;
  }
  
  .team-container {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    position: relative;
    overflow: hidden;
    gap: 40px;
    z-index: 2;
  }
  
  .team-wrapper {
    width: 600px;
    height: 800px;
    overflow: hidden;
    position: relative;
  }
  
  .team-container-logo {
    width: 100%;
    height: 100%;
    background-color: #080D20;
    background-image: url('https://softwave-cdn.com/upload/overlays/team_vs_team_blue_softwave.png');
    background-size: cover;
    justify-content: center;
    display: grid;
    align-items: center;
    position: absolute;
    top: 0;
  }
  
  .team-logo {
    width: 400px;
    height: auto;
  }
  
  .team-name {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .svg_vs_animation_main {
    width: 200px;
  }
  
  @keyframes SvgIn {
    0% {
      transform: scale(0);
      opacity: 1;
    }
  
    70% {
      transform: scale(0);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 1;
    }
  
    25% {
      transform: translateY(0%);
      opacity: 1;
      transform: scale(1);
    }
  
    35% {
      transform: scale(1.3);
    }
  
    65% {
      transform: scale(1.3);
    }
  
    85% {
      transform: translateY(0%);
      opacity: 1;
      transform: scale(1);
    }
  
    100% {
      transform: translateY(100%);
      opacity: 1;
    }
  }
  
  @keyframes showBackground {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  @keyframes LeftTeamAnimation {
    0% {
      transform: translateX(-100%);
      opacity: 1;
    }
  
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes RightTeamAnimation {
    0% {
      transform: translateX(100%);
      opacity: 1;
    }
  
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .team-name-div-btn-left {
    position: absolute;
    bottom: 100px;
    z-index: 100;
    left: 250px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%, 0 69%);
    width: 540px;
    background-color: #8A6F2C;
    color: #fff;
    height: 80px;
  }
  
  .team-name-div-btn-right {
    position: absolute;
    bottom: 100px;
    z-index: 100;
    right: 250px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%, 0 69%);
    width: 540px;
    background-color: #8A6F2C;
    color: #fff;
    height: 80px;
  }
  
  .team-name-inside {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 38px;
    font-family: "Switzer-Variable";
    font-weight: bold;
  }
  
  .animate .team-container-logo.team1 {
    animation: LeftTeamAnimation 0.5s forwards;
    animation-delay: 0.8s;
    opacity: 0;
  }
  
  .animate .team-container-logo.team2 {
    animation: RightTeamAnimation 0.5s forwards;
    animation-delay: 0.8s;
    opacity: 0;
  }
  
  .animate .team-name-div-btn-left {
    animation: LeftTeamAnimation 0.5s forwards;
    animation-delay: 0.8s;
    opacity: 0;
  }
  
  .animate .team-name-div-btn-right {
    animation: RightTeamAnimation 0.5s forwards;
    animation-delay: 0.8s;
    opacity: 0;
  }
  
  .animate .svg_vs_animation_main {
    opacity: 0;
    animation: SvgIn 1s ease-in-out forwards;
  }
  </style>
  